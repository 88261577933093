import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';
import { Reverse1999Psychube } from '../../../modules/reverse/common/components/rev-psychube';

const RevShouldFlutterpage: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should you pull Flutterpage?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_flutterpage.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should you pull Flutterpage?</h1>
          <h2>
            Not sure if pulling Flutterpage is the right move? No worries! Check
            our review and guide for her!
          </h2>
          <p>
            Last updated: <strong>10/Feb/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content ">
        <SectionHeader title="Should You Pull? (TLDR)" />
        <div className="employees-container-card-mode-rev">
          <Reverse1999Character
            slug="flutterpage"
            mode="icon"
            showTags
            showIcon
            enablePopover
          />
        </div>
        <p>You should pull Flutterpage</p>
        <ul>
          <li>
            If your team has a lot of follow-up attack characters, she is an
            easy slot in. As more action allows her to generate gusts which is
            one of the key parts about her gameplay.
          </li>
          <li>
            If you want a unit who can increase your team's AP, or have AP
            Greedy characters. She is the first character who can do this so
            far.
          </li>
          <li>
            If you want to boost your team's DMG output from Reality ATK,
            Follow-Ups, etc
          </li>
        </ul>
        <p>
          Yes, you should definitely pull for Flutterpage, especially if you
          have a setup for her. She’s a very versatile character, especially
          with the increase in follow-up attack characters. While she might not
          be the strongest in raw damage numbers, she’s a very rewarding
          character when used in the right teams (Follow-Up attack teams). Her
          kit revolves around managing stacks of [Gust] and granting various
          powerful effects (Reality DMG buffs, extra attack buffs, Genesis DMG
          buffs, Crit buffs). Her ultimate allows her to recover Eureka and
          convert all her skills into a Mass Attack. She’s also the first
          character who can increase AP. Allowing for more actions, which also
          directly works with her own kit and pretty much any team benefits from
          increased AP.
        </p>
        <SectionHeader title="Review" />
        <p>
          Flutterpage is a 6 star rarity character from the Star afflatus who
          deals Reality damage and belongs to the Sub DPS class. Her kit
          revolves around managing stacks of [Gust] and utilizing them to
          enhance her abilities and those of her team. You gain [Gust] in
          several ways, first is in her skill “Knock, Knock” which just grants
          you a stack for simply using it and the skill itself other than that
          is a simple single target ATK. Then she can gain [Gust] via her
          Insights 1 and 3. 1 allows her to gain a stack every time an ally
          acts, which is so helpful that she can increase AP using her skill
          “Cotton Kite” and why she pairs so well with follow-up attack
          characters. Insight 3 gives her [Gust] when she enters battle, 10
          whole stacks of it to start you off.
        </p>
        <p>
          Now that you know how to gain [Gust] what even is it used for? It is
          the crux of her kit due to her generation of forcefields that are
          dependent on the amount of [Gust] she has. Forcefield is essentially a
          team buff effect, you cannot have more than one Forcefield at a time.
          The effects are as follows. At 5 stacks or above, she gains [Paper
          Parade]; at 15 stacks or above, she gains [Wave of Sheets]; at 25
          stacks or above, she gains [Adieu, Mother Earth]. The more stacks, the
          stronger it gets, at [Paper Parade] level she increases Reality DMG,
          and when performing an extra action, it’ll consume 2 of her [Eureka]
          to deal even more Reality DMG. At [Waves of Sheets] the effect from
          before still exists but now she also has a buff when performing an
          extra action, as it increases the DMG dealt and Genesis DMG for 2% for
          every stack of [Gust] you have. At the last level [Adieu, Mother
          Earth] same as before, but now when performing an extra action, it is
          guaranteed to crit and DMG dealt and Genesis DMG is once again
          increased
        </p>
        <p>
          That was a bit long, but it’s the most important part of her kit. With
          the amount of follow-up attack characters in the game now, generating
          [Gust] is quite easy and she can even grant more AP herself, which
          again just helps make her stacks greatly. Something I didn’t go in
          super in-depth with yet is her Ultimate which shines in these setups,
          as she boosts everyone's damage output while dishing out a series of
          Mass Attacks that can deal considerable damage, especially when the
          Gust stacks are maxed out. Her Ultimate is essentially a self-channel
          that recovers all Eureka and transforms all of her own incantations
          into a mass attack known as [Regards from the Sky] for about 3 rounds.
          She cannot gain Moxie while this is happening, but she will gain up to
          3 Moxie, depending on the current amount of [Gust] after the channel
          effect goes away.
        </p>
        <p>
          In summary Flutterpage is a very good character that thrives in a team
          that utilizes a lot of action. Especially with how many characters in
          recent times have Follow-Up attacks, it almost guarantees that she
          will be heavily utilized in future teams.
        </p>
        <h6>Pros & Cons</h6>
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Thrives in teams that utilize extra action. Thanks to her
                    [Gust] stacking with any action an ally makes and force
                    fields which are further strengthened depending on how many
                    stacks you have, this results in a continuous loop of
                    actions and significant damage, especially when the
                    forcefield is at its maximum potential.
                  </li>
                  <li>
                    Said forcefield increases the DMG of various types of
                    attacks and DMG.
                  </li>
                  <li>
                    {' '}
                    Her ultimate skill converts all her skills to Mass Attacks,
                    allowing for massive damage over several turns when her
                    force fields are up. This is very strong in battles
                    involving multiple enemies or when you need a burst of
                    damage.
                  </li>
                  <li>She can increase AP for her team via her skill.</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    She might struggle to deal damage against enemies with high
                    reality defense.
                  </li>
                  <li>
                    To truly shine, Flutterpage needs a team built around
                    Follow-Up Attacks.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Portrays" />
        <p>
          She is a 6-star character, so her portraits are not the easiest to
          obtain, and for F2P players, it’s quite hard to get them. However,
          some of her portrays are very helpful, like her first one, which
          increases her damage, but more importantly, it makes [Continuous
          Action I] last one more round. This is very beneficial as it gives you
          even more actions to work with for one more round. This might be worth
          getting as you only require one dupe.
        </p>
        <SectionHeader title="Great Synergies" />
        <p>
          Flutterpage works best with characters who have follow-up attacks or
          any form of extra action. There are a variety of these characters, so
          I would choose based on your team's setup needs. Windsong is, of
          course, a great option, as she is not only a FUA character but also
          one of the strongest damage dealers in the game. With Flutterpage,
          you'll be able to boost her damage tremendously. In general, she works
          well with most Reality DMG dealers. Other options include pairing her
          with 37 or Lilya, who meet both her Star and FUA requirements.
          Essentially, you always want to ensure you can consistently spam FUAs
          for all your teams.
        </p>
        <p>Notable synergies include:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="windsong"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p></p>
        <SectionHeader title="Psychubes" />
        <p>Here are the best Psychubes you can use on Flutterpage:</p>
        <div className={`employees-container psychube`}>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="on-the-hand-of-time"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="addio-coda"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>
          On the Hands of Time: A Psychube made directly for her and makes use
          of self channeling Ultimate by boosting her DMG. It also increases
          CRIT Dmg every time she performs an extra action.
        </p>
        <p>
          Addio Coda: Another great Psychube for Flutterpage due to herself
          being able to consume Eureka, so she can boost the DMG of all allies.
          And since she is a Star Afflatus character, you get some extra DMG
          from Impromptu.
        </p>
        <SectionHeader title="Resonance & Resonance Pattern" />
        <h5>Resonances</h5>
        <h6>R10 Crit Build (Recommended)</h6>
        <StaticImage
          src="../../../images/reverse/generic/flutterpage_r_1.webp"
          alt="Guides"
        />
        <h5>Resonance Pattern</h5>
        <p>
          <strong>Genuinity</strong>
        </p>
        <p>
          <strong>Aspirational</strong>
        </p>
        <SectionHeader title="Teams" />
        <p>
          A A few suggestions are listed below. 3-People teams are highlighted,
          with the 4th slot added at the side to provide a 4-P variation of the
          same team or even insights on possible variations of the 3-P team.
        </p>
        <h5>Standard Teams</h5>
        <div className="team-box">
          <h5 className="star">
            Fluttering for the Win-dsong
            <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="windsong"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="flutterpage"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Sub DPS/Buffer</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="lopera"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Vila</strong>
              </li>
              <li>
                Flutterpage allows Windsong to go from 10-turn cycles to mere
                3-turn cycles, utilizing the generated FUAs to upkeep FP's
                Forcefield buff and thus massively increase Windsong's damage
                done. The short cycle also allows Lopera's buff stacks to be of
                note. Vila can provide healing if needed, and she doesn’t need
                much AP.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            Follow-Up/Crit
            <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="lilya"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="flutterpage"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Sub DPS/Buffer</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="37"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Ms. NewBabel</strong>
              </li>
              <li>
                It orients itself around FUA spam through Lilya and Ms-Newbabel,
                with Euphoria enabling both Flutterpage and 37 to charge
                simultaneously. Flutterpage should thrive under these settings,
                benefiting from the frequent follow-up attacks she will receive,
                significantly boosting the team's damage. This is an example of
                a great team for generating an extreme amount of constant Gust
                stacks. Lilya benefits greatly from the Crit support provided by
                Flutterpage and 37.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            Star Team
            <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="37"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="anjo-nala"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Sub DPS</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="flutterpage"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Sub DPS/Buffer</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Vila</strong>
              </li>
              <li>
                The team is oriented around the Star [Contract] (Anjo-Nala’s
                passive allows her to bind herself to 37 increasing Crit Rate
                and Mental Debuff), allowing 37 and Anjo-Nala to permanently
                crowd-control (CC) the opposing team through [Captivated] while
                Flutterpage reliably enhances Follow-Up Attack (FUA) damage.
                Vila is, once again, a reliable sustain unit who is AP-friendly,
                capable of clearing negative statuses, and providing healing.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldFlutterpage;

export const Head: React.FC = () => (
  <Seo
    title="Should you pull Flutterpage? | Reverse: 1999 | Prydwen Institute"
    description="Not sure if pulling Flutterpage is the right move? No worries! Check our review and guide for her!"
    game="reverse"
  />
);
